<template>
  <div class="formview">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4">
          <div class="text-center">
            <h3 class="formheader f-semibold text-dark">Reset Password</h3>
          </div>
          <form @submit.prevent="submitForm">
            <div class="my-5">
              <div class="form-group mb-3">
                <div class="myformgrp">
                  <input
                    id="password"
                    :type="showpass ? 'text' : 'password'"
                    placeholder="Create Password"
                    required
                    v-model="password"
                    class="form-control text-dark"
                    @keyup="checkp"
                  />
                  <span @click="togglepass">{{
                    showpass ? "Hide" : "Show"
                  }}</span>
                </div>
                <p class="error" v-if="perr">{{ perr }}</p>
              </div>
              <div class="form-group mb-3">
                <div class="myformgrp">
                  <input
                    id="confirm"
                    :type="showconfirm ? 'text' : 'password'"
                    placeholder="Confirm Password"
                    required
                    v-model="confirm_password"
                    class="form-control text-dark"
                    @keyup="checkc"
                  />
                  <span @click="toggleconfirm">{{
                    showconfirm ? "Hide" : "Show"
                  }}</span>
                </div>
                <p class="error" v-if="cerr">{{ cerr }}</p>
              </div>
              <div class="form-group text-center mt-5">
                <button
                  :disabled="loading || pdis || cdis"
                  class="
                    btn btn-imp-secondary
                    bg-primary
                    btn-icon
                    text-secondary
                    py-3
                    px-5
                    btn-block
                  "
                  type="submit"
                >
                  <span>Create password</span>
                  <BtnLoading v-if="loading" class="btn-loading" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BtnLoading from "@/components/BtnLoading.vue";

export default {
  data() {
    return {
      password: "",
      confirm_password: "",
      loading: false,
      showpass: false,
      showconfirm: false,
      hash: this.$route.query.hash,
      id: this.$route.query.id,
      pdis: true,
      cdis: true,
      perr: false,
      cerr: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    const { id, hash } = to.query;
    if (!id || !hash) {
      return next("/login");
    }
    next();
  },
  components: { BtnLoading },
  methods: {
    togglepass() {
      this.showpass = !this.showpass;
    },
    toggleconfirm() {
      this.showconfirm = !this.showconfirm;
    },
    check(e) {
      if (e.target.value.length < 8) {
        return false;
      } else {
        return true;
      }
    },
    checkp(e) {
      if (!this.check(e)) {
        this.perr = "Passwords must be at least 8 characters";
        this.pdis = true;
      } else {
        this.perr = false;
        this.pdis = false;
      }
    },
    checkc(e) {
      if (!this.check(e)) {
        this.cerr = "Passwords must be at least 8 characters";
        this.cdis = true;
      } else {
        this.cerr = false;
        this.cdis = false;
      }
    },
    submitForm() {
      if (this.password !== this.confirm_password) {
        this.$toast.error(
          "Password",
          "Password Mismatch, check passowrd",
          this.$toastPosition
        );
      } else {
        this.loading = true;
        let data = {
          id: this.id,
          hash: this.hash,
          password: this.password,
        };
        data = this.$helpers.encrypt(JSON.stringify(data));

        data = {
          data,
        };
        let payload = {
          data,
          path: "/user/reset-password-confirmation",
        };
        this.$store
          .dispatch("authRequest", payload)
          .then((resp) => {
            this.loading = false;
            if (resp.status === 200) {
              this.$toast.success(
                "Reset password",
                "Password Reset Successful",
                this.$toastPosition
              );
              this.$toast.success(
                "Reset password",
                "You can now sign in with your new password",
                this.$toastPosition
              );
              this.$router.push({ name: "login" });
            }
          })
          .catch((err) => {
            if (err.response) {
              let decr = JSON.parse(
                this.$helpers.decrypt(err.response.data.data)
              );
              err.response.data = decr;
              this.$toast.info(
                "Reset password",
                err.response.data.message,
                this.$toastPosition
              );
            } else {
              this.$toast.info(
                "Reset password",
                "Unable to send email address, check the details and try again",
                this.$toastPosition
              );
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.w-back {
  font-weight: bold;
  font-size: 16px;
  line-height: 52px;
  color: #255e13;
}
.formheader f-bold {
  font-size: 24px;
  color: #121113;
}
.formview {
  padding: 55px 0px;
  min-height: 90vh;
  padding-top: 15vh;
}
div.myformgrp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border: 1px solid #cad1d7;
  padding-right: 0.75rem;
}
.myformgrp input {
  border: none;
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  box-shadow: none;
}
.myformgrp span {
  cursor: pointer;
}
.myformgrp span:hover {
  color: #255e13;
}
.error {
  color: red;
}
</style>